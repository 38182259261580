@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}
/* 
.home-page {
   overflow-x: visible !important;
} */

body {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background-color: #fff;
}

/* ::-webkit-scrollbar {display:block;} */

.spinner {
   width: 56px;
   height: 56px;
   border-radius: 50%;
   background: radial-gradient(farthest-side,rgb(228 136 15) 94%,#0000) top/9px 9px no-repeat,
          conic-gradient(#0000 30%,rgb(228 136 15));
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
   animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}

.navbar-fixed {
   transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
 }
 .navbar-hidden {
   opacity: 0;
   transform: translateX(100%);
 }
 .navbar-visible {
   opacity: 1;
   transform: translateX(0);
 }

 /* src/StarRating.css */
.star-rating {
  display: flex;
  direction: row;
}

.star {
  font-size: 2rem;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.star.filled {
  color: gold;
}
