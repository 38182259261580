/* header{
  z-index: 10001 !important;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.navbar-fixed {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.navbar-hidden {
  opacity: 0;
  transform: translateX(100%);
}
.navbar-visible {
  opacity: 1;
  transform: translateX(0);
}

